import {  RiSettings2Fill } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function SettingsScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'App Settings'} navCardLink={'/appSettings'} ><RiSettings2Fill /></NavCard>
        
        </NavCardList>
    )
}

export default SettingsScreen