import { useState, useEffect } from "react"
import axios from "axios"
import { RAMADAN_API } from "../../../Utilities/APIs"



function ViewRamadan({

    //common props
    targetID, employee

}) {

    const [ramadanInfo, setRamadanInfo] = useState(null)


    useEffect(() => {

        async function fetchAndSetRamadanInfo() {

            const { data } = await axios.get(RAMADAN_API + 'singleDateTimes/' + targetID)
            setRamadanInfo(data);
        }
        fetchAndSetRamadanInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {ramadanInfo &&
                <>  
                    <h1>Date</h1>
                    <p>{new Date(ramadanInfo?.date).toDateString()}</p>
                    <h1>Fajr</h1>
                    <p>{ramadanInfo?.fajr}</p>
                    <h1>Dhuhr</h1>
                    <p>{ramadanInfo?.dhuhr}</p>
                    <h1>Asr</h1>
                    <p>{ramadanInfo?.asr}</p>
                    <h1>Maghrib</h1>
                    <p>{ramadanInfo?.maghrib}</p>
                    <h1>Isha</h1>
                    <p>{ramadanInfo?.isha}</p>
                    <h1>Seheri</h1>
                    <p>{ramadanInfo?.sehri}</p>
                    <h1>Iftar</h1>
                    <p>{ramadanInfo?.iftar}</p>
                    
                </>
            }
        </div>
    )
}

export default ViewRamadan