import React from 'react'
import { RiStarFill } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function HomeScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Ramadan'} navCardLink={'/ramadanScreen'} ><RiStarFill /></NavCard>
        </NavCardList>
    )
}

export default HomeScreen