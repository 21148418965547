import { backendUrl, spacesBucketUrl } from "../Constants"

export const BASE_URL = backendUrl
export const IMAGE_URL = spacesBucketUrl
export const API_URL = BASE_URL + 'api/'

export const EMPLOYEES_API = API_URL + 'employees/'
export const DESIGNATIONS_API = API_URL + 'designations/'
export const EMPLOYEE_DESIGNATIONS_API = API_URL + 'employeeDesignations/'


export const APP_SETTINGS_API = API_URL + 'appSettings/'
export const RAMADAN_API = API_URL + 'timetables/'



