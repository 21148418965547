import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"

// import { CLIENTS_API, CONTENT_GENRES_API } from "../../../Utilities/APIs"
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"

import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"

import Modal from "../../Partials/Elements/Modal/Modal"

import CRUDDeleteBoilerPlate from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import { RAMADAN_API } from "../../../Utilities/APIs"
import CreateRamadan from "./CreateRamadan"
import ViewRamadan from "./ViewRamadan"
import UpdateRamadan from "./UpdateRamadan"




function RamadanScreen() {

    const [ramadans, setRamadans] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    const { employee } = useSelector(state => state.auth)

    useEffect(() => {


        async function fetchAndSetLogistics() {
            const { data } = await axios.get(RAMADAN_API)
            setRamadans(data)
        }
        fetchAndSetLogistics()


    }, [toggleFetch])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Ramadan (${ramadans?.length} in total)`}</h1>
                    <CreateButton
                        screenTopicSingular='Ramadan'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Date' />
                        {/* <CRUDth th='Fajr' /> */}
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {ramadans?.map(ramadan => (
                            <CRUDTableRow key={ramadan._id}>
                                <ShortTextCell text={new Date(ramadan.date).toDateString()} />
                                {/* <ShortTextCell text={ramadan.fajr} /> */}


                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        // targetID={
                                        //     new Date(ramadan.date)
                                        // }
                                        targetID={ramadan.date}
                                        setTargetID={setTargetID}
                                    />
                                    <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={ramadan._id}
                                        setTargetID={setTargetID}
                                    />

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Ramadan`) ||
                        (showUpdateForm && `Update Ramadan`) ||
                        (showDeleteSection && `Delete Ramadan`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateRamadan
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateRamadan
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            // api={LOGISTICS_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Ramadan`)
                    }
                >
                    <ViewRamadan
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default RamadanScreen