import {Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../Redux/Features/Auth/authSlice'

import './App.css'

import NavBar from './Partials/Sections/NavBar/NavBar'
import SideBar from './Partials/Sections/SideBar/SideBar'
import Login from './Screens/AuthScreens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees'
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen'
import Employees from './Screens/AuthScreens/Employees/Employees'
import AppSettings from './Screens/AppSettings/AppSettings'
import Register from './Screens/AuthScreens/Register/Register'
import SettingsScreen from './Screens/Settings/SettingsScreen'
import OrderSettings from './Screens/Settings/OrderSettings'
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne'
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree'
import HomeScreen from './Screens/HomeScreen/HomeScreen'
import RamadanScreen from './Screens/RamadanScreen/RamadanScreen'



function App() {

  const {employee} = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  axios.interceptors.response.use(
    (res) => {
       return res;
    },
    (err) => {
      if (err.response.status === 401) {
        dispatch(logout())
      }
       return Promise.reject(err);
    }
  );

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className='screen-container'>

            <Routes>

              <Route path='/login' element={!employee ? <Login /> : <Navigate to={'/'} />} />
              <Route path='/register/:token' element={!employee ? <Register /> : <Navigate to={'/'} />} />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route path="/passwordReset/:token" element={<RecoverPassThree />} />
              
              <Route path='/employeeScreens' element={employee ? <EmployeesScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employeeInvites' element={employee ? <InviteEmployees /> : <Navigate to={'/login'} />} />
              <Route path='/employees' element={employee ? <Employees /> : <Navigate to={'/login'} />} />

              <Route path='/' element={employee ? <Dashboard /> : <Navigate to={'/login'} />} />
              <Route path='/homeScreen' element={employee ? <HomeScreen /> : <Navigate to={'/login'} />} />
              <Route path='/ramadanScreen' element={employee ? <RamadanScreen /> : <Navigate to={'/login'} />} />

              <Route path='/settingsScreen' element={employee ? <SettingsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/appSettings' element={employee ? <AppSettings /> : <Navigate to={'/login'} />} />
              <Route path='/orderSettings' element={employee ? <OrderSettings /> : <Navigate to={'/login'} />} />
              
           
            </Routes>
            
          </section>
        </main>
      </BrowserRouter>
    </section>
  )
}

export default App
