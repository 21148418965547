import { useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import { RAMADAN_API } from "../../../Utilities/APIs"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"



function CreateRamadan({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {
    const [date, setDate] = useState('')
    const [fajr, setFajr] = useState('')
    const [dhuhr, setDhuhr] = useState('')
    const [asr, setAsr] = useState('')
    const [maghrib, setMaghrib] = useState('')
    const [isha, setIsha] = useState('')
    const [sehri, setSehri] = useState('')
    const [iftar, setIftar] = useState('')

    console.log(date, fajr);

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = { date, fajr, dhuhr, asr, maghrib, isha, sehri, iftar }


        const response = await axios.post(RAMADAN_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <h1 className="input_field_label">Date</h1>
            <input
                className="input_field"
                type="date"
                id="date"
                name="date"
                onChange={(e) => setDate(e.target.value)}
            />
            <h1 className="input_field_label">Fajr</h1>
            <input
                type="time"
                id="appt"
                name="appt"
                className="input_field"
                onChange={(e) => setFajr(e.target.value)}
            />
            <h1 className="input_field_label">Dhuhr</h1>
            <input
                type="time"
                id="appt"
                name="appt"
                className="input_field"
                onChange={(e) => setDhuhr(e.target.value)}
            />
            <h1 className="input_field_label">Asor</h1>
            <input
                type="time"
                id="appt"
                name="appt"
                className="input_field"
                onChange={(e) => setAsr(e.target.value)}
            />
            <h1 className="input_field_label">Maghrib</h1>
            <input
                type="time"
                id="appt"
                name="appt"
                className="input_field"
                onChange={(e) => setMaghrib(e.target.value)}
            />
            <h1 className="input_field_label">Isha</h1>
            <input
                type="time"
                id="appt"
                name="appt"
                className="input_field"
                onChange={(e) => setIsha(e.target.value)}
            />
            <h1 className="input_field_label">Sehri</h1>
            <input
                type="time"
                id="appt"
                name="appt"
                className="input_field"
                onChange={(e) => setSehri(e.target.value)}
            />
            <h1 className="input_field_label">Ifter</h1>
            <input
                type="time"
                id="appt"
                name="appt"
                className="input_field"
                onChange={(e) => setIftar(e.target.value)}
            />



            <FormSubmitButton text='Create Ramadan' />
        </Form>
    )
}

export default CreateRamadan