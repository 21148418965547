import { useEffect, useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import { RAMADAN_API } from "../../../Utilities/APIs"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"



function UpdateRamadan({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {
    const [date, setDate] = useState('')
    console.log("🚀 ~ file: UpdateRamadan.js:15 ~ date:", date)
    const [fajr, setFajr] = useState('')
    const [dhuhr, setDhuhr] = useState('')
    const [asr, setAsr] = useState('')
    const [maghrib, setMaghrib] = useState('')
    const [isha, setIsha] = useState('')
    const [sehri, setSehri] = useState('')
    const [iftar, setIftar] = useState('')

    useEffect(() => {
        async function fetchAndSetRamadanInfo() {

            const { data } = await axios.get(RAMADAN_API + 'getSingleIdTimes/' + targetID)
            const dateObject = new Date(data.date)
            // setDate(`${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${dateObject.getDate()}`)
            setDate(`${dateObject.toISOString().slice(0,10)}`)
            setFajr(data.fajr)
            setDhuhr(data.dhuhr)
            setAsr(data.asr)
            setMaghrib(data.maghrib)
            setIsha(data.isha)
            setSehri(data.sehri)
            setIftar(data.iftar)
        }
        fetchAndSetRamadanInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = { date, fajr, dhuhr, asr, maghrib, isha, sehri, iftar }


        const response = await axios.patch(RAMADAN_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
           <h1 className="input_field_label">Date</h1>
            <input
                className="input_field"
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
            />
            <h1 className="input_field_label">Fajr</h1>
            <input
                type="time"
                id="appt"
                value={fajr}
                className="input_field"
                onChange={(e) => setFajr(e.target.value)}
            />
            <h1 className="input_field_label">Dhuhr</h1>
            <input
                type="time"
                id="appt"
                value={dhuhr}
                className="input_field"
                onChange={(e) => setDhuhr(e.target.value)}
            />
            <h1 className="input_field_label">Asor</h1>
            <input
                type="time"
                id="appt"
                value={asr}
                className="input_field"
                onChange={(e) => setAsr(e.target.value)}
            />
            <h1 className="input_field_label">Maghrib</h1>
            <input
                type="time"
                id="appt"
                value={maghrib}
                className="input_field"
                onChange={(e) => setMaghrib(e.target.value)}
            />
            <h1 className="input_field_label">Isha</h1>
            <input
                type="time"
                id="appt"
                value={isha}
                className="input_field"
                onChange={(e) => setIsha(e.target.value)}
            />
            <h1 className="input_field_label">Sehri</h1>
            <input
                type="time"
                id="appt"
                value={sehri}
                className="input_field"
                onChange={(e) => setSehri(e.target.value)}
            />
            <h1 className="input_field_label">Ifter</h1>
            <input
                type="time"
                id="appt"
                value={iftar}
                className="input_field"
                onChange={(e) => setIftar(e.target.value)}
            />




            <FormSubmitButton text='Update Ramadan' />
        </Form>
    )
}

export default UpdateRamadan